.frontend-page .centered-hero-container {
    padding: 60px 24px;
}

.frontend-page .centered-hero-container .centered-hero-title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 24px;
    text-align: center;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
}

.frontend-page .centered-hero-container .centered-hero-subtitle {
    font-size: 20px;
    line-height: 42px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    text-align: center;
    margin-bottom: 60px;
}

.frontend-page .centered-hero-container .centered-hero-buttons {
    max-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: auto;
}

.frontend-page .centered-hero-container button {
    width: 100%;
    margin: 0 auto;
    padding: 12px 24px;
    border-radius: 4px;
    background: var(--button-color);
    border-color: var(--button-color);
    font-size: 16px;
    letter-spacing: 0.46px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    text-align: center;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.frontend-page .centered-hero-buttons button:first-of-type {
    box-shadow: none;
    background: transparent;
    border: 1px solid #000933A6;
    color: var(--background-color);
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: 0.46px;
    max-height: 52px;
}

.frontend-page .centered-hero-buttons button:first-of-type:active {
    border-color: #000933A6;
    background: transparent;
    color: var(--background-color);
}

.frontend-page .centered-hero-container button:not(:first-of-type):hover {
    background: var(--button-hover-color);
    border-color: var(--button-hover-color);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.frontend-page .centered-hero-container button:not(:first-of-type):active {
    background: var(--button-hover-color);
    border-color: var(--button-hover-color);
    color: var(--text-color);
}
