.admin-page .course-container .course-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 450px;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.admin-page .course-container .MuiAutocomplete-popper * {
    max-height: 144px;
}

.admin-page .course-container .MuiAutocomplete-popper > div {
    max-height: 200px;
    overflow-y: hidden;
}

.admin-page .course-container .thumbnail-image {
    margin-bottom: 24px;
}
