.frontend-page .course-container .course-image-skeleton {
    width: 100vw;
    margin-top: -40px;
    margin-left: calc((-100vw + 1320px - 48px) / 2);
    max-height: 400px;
    min-height: 400px;
}

.frontend-page .course-container .course-image-skeleton .course-image {
    margin: 0;
}

@media (max-width: 1399px) {
    .frontend-page .course-container .course-image-skeleton {
        margin-left: calc((-100vw + 1140px - 48px) / 2);
    }
}

@media (max-width: 1199px) {
    .frontend-page .course-container .course-image-skeleton {
        margin-left: calc((-100vw + 960px - 48px) / 2);
    }
}

@media (max-width: 991px) {
    .frontend-page .course-container .course-image-skeleton {
        margin-left: calc((-100vw + 720px - 48px) / 2);
    }
}

@media (max-width: 767px) {
    .frontend-page .course-container .course-image-skeleton {
        margin-left: calc((-100vw + 540px - 48px) / 2);
    }
}

@media (max-width: 575px) {
    .frontend-page .course-container .course-image-skeleton {
        max-height: 244px;
        min-height: 244px;
        margin-left: -24px;
    }

    .frontend-page .course-container .MuiButton-root {
        width: 100%;
    }
}

