.frontend-page .about-page-container {
    padding: 92px 24px 50px;
    max-width: 1320px;
    margin: auto;
    min-height: 100%;
    font-size: 0;
}

.frontend-page .about-page-container > div {
    font-size: initial;
}

.frontend-page .about-page-container .about-page-skeleton {
    width: 100%;
    height: calc(100vh - 146px);
}

@media (max-width: 1399px) {
    .frontend-page .about-page-container {
        max-width: 1140px;
    }
}

@media (max-width: 1199px) {
    .frontend-page .about-page-container {
        max-width: 960px;
    }
}

@media (max-width: 991px) {
    .frontend-page .about-page-container {
        max-width: 720px;
    }
}

@media (max-width: 767px) {
    .frontend-page .about-page-container {
        max-width: 540px;
    }
}

@media (max-width: 575px) {
    .frontend-page .about-page-container {
        max-width: unset;
    }
}
