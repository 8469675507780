@font-face {
  font-family: 'Exo Light';
  font-weight: 300;
  src: local('Exo Light'), url('./fonts/Exo.ttf') format('truetype');
}

@font-face {
  font-family: 'Exo Regular';
  font-weight: 400;
  src: local('Exo Regular'), url('./fonts/Exo.ttf') format('truetype');
}

@font-face {
  font-family: 'Exo Medium';
  font-weight: 500;
  src: local('Exo Medium'), url('./fonts/Exo.ttf') format('truetype');
}

@font-face {
  font-family: 'Exo SemiBold';
  font-weight: 600;
  src: local('Exo SemiBold'), url('./fonts/Exo.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  font-family: 'Exo Regular', Roboto, Helvetica, Arial, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --text-color: #fff;
  --light-background-color: #E9ECEF;
  --button-color: #008552;
  --button-hover-color: #01623d;
  --background-color: #212529;
  --error-color: #d32f2f;
  --badge-color: #ddd;
  --border-color: #ccc;
  --button-border-color: #000933A6;
  --filter-border-color: #7A757F;
  --filter-text-color: #49454E;
  --filter-hover-color: #49454F14;
  --filter-enabled-color: #C1DBD1;
  --filter-enabled-hover-color: #1D192B14;
}

.frontend-container,
.admin-login-container,
.admin-container {
  height: 100vh;
  background: var(--text-color);
  overflow: auto;
  scrollbar-width: none;
}

.frontend-page .MuiFormControl-root.language-switcher {
  width: 37px;
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.MuiPaper-root .MuiFormControl-root.language-switcher {
  width: fit-content;
}

.frontend-page .MuiFormControl-root.language-switcher,
.admin-page .MuiFormControl-root.language-switcher,
.language-switcher .MuiInputBase-root,
.language-switcher .MuiInputBase-root:hover,
.language-switcher .MuiInputBase-root.Mui-focused,
.language-switcher .MuiInputBase-input:focus {
  background: transparent;
}

.language-switcher .MuiInputBase-input {
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: center;
}

.language-switcher .MuiInputBase-root:before,
.language-switcher .MuiInputBase-root:after {
  display: none;
}

.frontend-page .row,
.admin-page .row {
  flex-wrap: nowrap;
}

.frontend-page .row > *,
.admin-page .row > * {
  flex-shrink: 1;
  padding-left: 0;
  padding-right: 0;
}

.frontend-page .MuiInputLabel-root,
.admin-page .MuiInputLabel-root {
  color: var(--background-color);
}

.frontend-page .MuiInputLabel-root.Mui-focused,
.admin-page .MuiInputLabel-root.Mui-focused {
  color: var(--background-color);
}

.frontend-page .MuiInputLabel-root.Mui-focused.Mui-error,
.admin-page .MuiInputLabel-root.Mui-focused.Mui-error {
  color: var(--error-color);
}

.frontend-page .MuiOutlinedInput-root,
.admin-page .MuiOutlinedInput-root {
  color: var(--background-color);
}

.frontend-page .MuiOutlinedInput-notchedOutline,
.admin-page .MuiOutlinedInput-notchedOutline,
.MuiModal-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--background-color);
}

.frontend-page .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.frontend-page .Mui-focused .MuiOutlinedInput-notchedOutline,
.admin-page .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.admin-page .Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiModal-root .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiModal-root .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--background-color);
}

.frontend-page .MuiInputBase-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline,
.frontend-page .Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline,
.admin-page .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline,
.admin-page .Mui-error .MuiOutlinedInput-notchedOutline,
.MuiModal-root .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline,
.MuiModal-root .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var(--error-color);
}

.frontend-page .MuiInputBase-root.Mui-error .MuiInputBase-input::placeholder,
.admin-page .MuiInputBase-root.Mui-error .MuiInputBase-input::placeholder {
  color: var(--error-color);
}

.frontend-page .MuiFormControl-root,
.admin-page .MuiFormControl-root {
  background: var(--text-color);
}

[role="presentation"] .MuiPaper-elevation .Mui-focused .MuiOutlinedInput-notchedOutline,
[role="presentation"] .MuiPaper-elevation .MuiInputBase-root:after,
[role="presentation"] .MuiPaper-elevation .MuiInputBase-root.Mui-focused:after,
[role="tooltip"] .MuiPaper-elevation .Mui-focused .MuiOutlinedInput-notchedOutline,
[role="tooltip"] .MuiPaper-elevation .MuiInputBase-root:after,
[role="tooltip"] .MuiPaper-elevation .MuiInputBase-root.Mui-focused:after {
  border-color: var(--background-color);
}

[role="presentation"] .MuiPaper-elevation .Mui-checked,
[role="presentation"] .MuiPaper-elevation .MuiCheckbox-indeterminate,
[role="presentation"] .MuiPaper-elevation .Mui-focused,
[role="tooltip"] .MuiPaper-elevation .Mui-checked,
[role="tooltip"] .MuiPaper-elevation .MuiCheckbox-indeterminate,
[role="tooltip"] .MuiPaper-elevation .Mui-focused {
  color: var(--background-color);
}

.frontend-page .MuiFormControl-root:not(.language-switcher) .MuiInputBase-root,
.admin-page .MuiFormControl-root:not(.language-switcher) .MuiInputBase-root {
  background: #fff;
}

.frontend-page .MuiFormControl-root.language-switcher .MuiInputBase-root,
.admin-page .MuiFormControl-root.language-switcher .MuiInputBase-root {
  height: 100%;
  border-radius: 0;
}

.frontend-page .MuiFormHelperText-root.Mui-error,
.admin-page .error-text,
.admin-page .helper-text,
.admin-page .MuiFormHelperText-root.Mui-error {
  text-align: center;
  margin-top: 4px;
}

.admin-page .helper-text {
  padding: 0 10%;
}

.admin-page .helper-text:first-of-type {
  margin-bottom: 4px;
}

.frontend-page .MuiButton-root {
  color: var(--text-color);
  background: var(--button-color);
  width: fit-content;
  padding: 6px 12px;
  margin: auto;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 16px;
}

.frontend-page .MuiButton-root.Mui-disabled {
  background: rgba(0, 0, 0, 0.26);
  color: var(--background-color);
}

.frontend-page .MuiButton-root:hover {
  background: var(--button-hover-color);
}

.frontend-page .MuiButton-root.Mui-disabled:hover {
  background: rgba(0, 0, 0, 0.26);
}

.admin-container {
  position: relative;
  padding-left: 88px;
}

.admin-page p {
  margin: 0;
}

.admin-page .language-switcher {
  position: absolute;
  top: 10px;
  right: 30px;
}

.admin-page .error-text,
.admin-page .MuiFormHelperText-root.Mui-error {
  color: var(--error-color);
}

.admin-page .row {
  width: 100%;
  display: flex;
  gap: 24px;
}

.admin-page .row > .MuiFormControl-root,
.admin-page .row > .MuiFormControlLabel-root,
.admin-page .row > .MuiAutocomplete-root {
  width: 100%;
  margin: 0;
  padding: 0;
}

.admin-page .MuiButton-root.action-button,
.MuiDialog-container .MuiButton-root {
  color: var(--text-color);
  background: var(--button-color);
}

.admin-page .MuiButton-root.action-button {
  width: fit-content;
  padding: 8px 16px;
  min-width: 200px;
  margin: auto;
  text-transform: capitalize;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.admin-page .MuiButton-root.action-button:hover,
.MuiDialog-container .MuiButton-root:hover {
  background: var(--button-hover-color);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.frontend-page .back-link,
.admin-page .back-link {
  display: flex;
  position: fixed;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;
}

.frontend-page .back-link {
  top: 80px;
  left: 24px;
  background: var(--badge-color);
  color: var(--background-color);
}

.admin-page .back-link {
  top: 24px;
  background: var(--button-color);
  color: var(--text-color);
}

.frontend-page .back-link:hover {
  background: var(--background-color);
  color: var(--text-color);
}

.admin-page .back-link:hover {
  background: var(--button-hover-color);
}

.frontend-page .back-link > svg,
.admin-page .back-link > svg {
  position: absolute;
  right: 0;
  margin: auto;
}

.frontend-page .back-link > svg {
  left: 2px;
}

.admin-page .back-link > svg {
  left: -2px;
}

.admin-page-container {
  min-height: 100vh;
  padding: 80px 40px;
}

.admin-page-container .admin-page-wrapper {
  position: relative;
}

.admin-page-container .admin-page-title {
  font-size: 24px;
  padding-bottom: 32px;
  margin-bottom: 32px;
}

.admin-page-container .admin-page-controls {
  text-align: right;
  margin-bottom: 24px;
  position: absolute;
  top: 40px;
  right: 0;
}

.admin-page-container .admin-page-controls > .MuiButton-root.action-button,
.MuiDialog-container .MuiButton-root:last-of-type {
  margin-left: 12px;
}

.admin-page-container .MuiDataGrid-overlayWrapper {
  min-height: 52px;
}

.admin-page-container .MuiDataGrid-overlayWrapperInner {
  max-height: 52px;
}

[role="dialog"] .MuiPaper-root .MuiPickersLayout-root .Mui-selected,
[role="dialog"] .MuiPaper-root .MuiPickersLayout-root .Mui-selected:hover {
  background-color: var(--background-color);
}

[role="dialog"] .MuiPaper-root .MuiButton-text {
  color: var(--background-color);
}

.not-found-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--background-color);
  color: var(--text-color);
  padding: 0 40px;
  text-align: center;
}

.not-found-container .not-found-text {
  font-size: 24px;
  margin-bottom: 12px;
}

.not-found-container a {
  font-size: 18px;
  color: var(--text-color);
}

.Toastify .Toastify__toast--success,
.Toastify .Toastify__toast--error,
.Toastify .Toastify__toast--success .Toastify__close-button,
.Toastify .Toastify__toast--error .Toastify__close-button {
  color: var(--text-color);
}

.Toastify .Toastify__toast--success {
  background: var(--button-hover-color);
}

.Toastify .Toastify__toast--error {
  background: var(--background-color);
}

.Toastify .Toastify__toast--success .Toastify__toast-icon,
.Toastify .Toastify__toast--error .Toastify__toast-icon {
  display: none;
}
