.admin-page .configurations-list .MuiAccordionSummary-content {
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
}

.admin-page .configurations-list .configuration-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    margin: 32px auto;
    max-width: 450px;
}
