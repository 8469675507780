.admin-page .login-container {
    display: flex;
    height: 100%;
    background: var(--text-color);
}

.admin-page .login-container .login-form-wrapper {
    margin: auto;
    padding: 60px;
    max-width: fit-content;
}

.admin-page .login-container .MuiButton-root {
    box-shadow: none;
    background: transparent;
    border: 1px solid #000933A6;
    color: var(--background-color);
    padding: 12px 24px;
    font-size: 16px;
    max-height: 52px;
}
