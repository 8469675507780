.frontend-page .course-container {
    padding: 92px 24px 50px;
    max-width: 1320px;
    margin: auto;
    min-height: 100%;
}

.frontend-page .course-container .course-image {
    max-height: 400px;
    object-fit: cover;
    width: 100vw;
    margin-top: -40px;
    margin-left: calc((-100vw + 1320px - 48px) / 2);
    min-height: 400px;
    min-width: 100vw;
}

.frontend-page .course-container .course-information {
    position: relative;
    margin-top: 36px;
    margin-left: 8px;
}

.frontend-page .course-container .course-title {
    font-size: 28px;
    font-family: 'Exo SemiBold', Roboto, Helvetica, Arial, sans-serif !important;
    margin-bottom: 4px;
}

.frontend-page .course-container .course-description {
    font-size: 18px;
    font-family: 'Exo Light', Roboto, Helvetica, Arial, sans-serif !important;
    white-space: break-spaces;
}

.frontend-page .course-container .course-details {
    margin: 24px 0 16px;
    width: fit-content;
}

.frontend-page .course-container .course-details > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.frontend-page .course-container .course-information-label {
    display: inline-block;
    font-family: 'Exo SemiBold', Roboto, Helvetica, Arial, sans-serif !important;
    padding: 8px;
    vertical-align: middle;
}

.frontend-page .course-container .course-information-value {
    display: inline-flex;
    padding: 8px;
    vertical-align: middle;
}

.frontend-page .course-container .MuiFormControl-root {
    margin-left: 8px;
    gap: 38px;
}

.frontend-page .course-container .MuiAutocomplete-root .MuiFormControl-root {
    max-width: 200px;
    margin-bottom: 16px;
}

.frontend-page .course-container .MuiFormControlLabel-root {
    align-items: start;
}

.frontend-page .course-container .MuiFormControlLabel-label {
    padding: 9px 0;
}

.frontend-page .course-container .MuiButton-root {
    padding: 8px 16px;
    min-width: 200px;
    margin-left: 0;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.frontend-page .course-container .MuiButton-root.diploma-button {
    margin-left: 24px;
}

.frontend-page .course-container .MuiButton-root:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.frontend-page .course-container .helper-text-wrapper {
    display: flex;
    margin-top: 10px;
    cursor: default;
}

.frontend-page .course-container .course-helper-text {
    flex-grow: 1;
    width: 0;
    margin: 0;
    font-size: 14px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    color: var(--background-color);
}

.frontend-page .course-container .course-helper-text a {
    margin: 0 4px;
    cursor: pointer;
    color: var(--background-color);
}

.frontend-page .course-container .course-helper-text a:last-of-type {
    margin-right: 0;
}

.frontend-page .course-container .player-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.frontend-page .course-container .course-iframe-container {
    position: relative;
    height: 100%;
    padding-top: 52px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.frontend-page .course-container .course-iframe-container.visible {
    opacity: 1;
}

.frontend-page .course-container .course-iframe {
    border-width: 0;
}

@media (max-width: 1399px) {
    .frontend-page .course-container {
        max-width: 1140px;
    }

    .frontend-page .course-container .course-image {
        margin-left: calc((-100vw + 1140px - 48px) / 2);
    }
}

@media (max-width: 1199px) {
    .frontend-page .course-container {
        max-width: 960px;
    }

    .frontend-page .course-container .course-image {
        margin-left: calc((-100vw + 960px - 48px) / 2);
    }
}

@media (max-width: 991px) {
    .frontend-page .course-container {
        max-width: 720px;
    }

    .frontend-page .course-container .course-image {
        margin-left: calc((-100vw + 720px - 48px) / 2);
    }
}

@media (max-width: 767px) {
    .frontend-page .course-container {
        max-width: 540px;
    }

    .frontend-page .course-container .course-image {
        margin-left: calc((-100vw + 540px - 48px) / 2);
    }
}

@media (max-width: 575px) {
    .frontend-page .course-container {
        max-width: unset;
    }

    .frontend-page .course-container .course-image {
        max-height: 244px;
        min-height: 244px;
        margin-left: -24px;
    }

    .frontend-page .course-container .MuiButton-root {
        width: 100%;
    }
}
