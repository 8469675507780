.frontend-page .header-container {
    position: fixed;
    width: 100%;
    background: var(--light-background-color);
    z-index: 50;
}

.frontend-page .header-container .desktop-header-container {
    display: flex;
    justify-content: space-between;
    max-width: 1320px;
    margin: auto;
    padding: 10px 24px;
}

.frontend-page .header-container .mobile-header-container {
    display: none;
    justify-content: space-between;
    max-width: 540px;
    margin: auto;
    padding: 10px 24px;
}

.drawer-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 50px 20px;
    min-width: 200px;
    gap: 8px;
    height: 100%;
}

.frontend-page .header-container a.header-link,
.frontend-page .header-container button.header-link,
.drawer-container a.header-link,
.drawer-container button.header-link {
    display: block;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    background: transparent;
    border: none;
    -webkit-appearance: none;
    padding-inline: 0;
    padding-block: 0;
    text-align: left;
}

.frontend-page .header-container a.header-link,
.frontend-page .header-container button.header-link {
    color: var(--background-color);
    margin-right: 16px;
}

.frontend-page .header-container .mobile-header-container a.header-link,
.frontend-page .header-container .mobile-header-container button.header-link {
    margin-right: 0;
}

.MuiPaper-root .drawer-container a.header-link {
    margin-top: auto;
}

.drawer-container a.header-link,
.drawer-container button.header-link {
    color: var(--background-color);
}

.frontend-page .header-container a.drawer-link {
    margin-right: 12px;
}

.frontend-page .header-container a.logo-link {
    max-height: 36px;
    height: 100%;
}

.frontend-page .header-container .logo-link img {
    max-width: 216px;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.frontend-page .header-container .logo-link:not(.active) img {
    cursor: pointer;
}

.frontend-page .header-container .left-links-container,
.frontend-page .header-container .right-links-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 1399px) {
    .frontend-page .header-container .desktop-header-container {
        max-width: 1140px;
    }
}

@media (max-width: 1199px) {
    .frontend-page .header-container .desktop-header-container {
        max-width: 960px;
    }
}

@media (max-width: 991px) {
    .frontend-page .header-container .desktop-header-container {
        max-width: 720px;
    }
}

@media (max-width: 767px) {
    .frontend-page .header-container .desktop-header-container {
        display: none;
    }

    .frontend-page .header-container .mobile-header-container {
        display: flex;
    }
}

@media (max-width: 575px) {
    .frontend-page .header-container .mobile-header-container {
        max-width: unset;
    }
}
