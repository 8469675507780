.admin-page .menu-container {
    position: fixed;
    height: 100%;
    padding: 150px 36px 60px 20px;
    background: var(--background-color);
    min-width: 220px;
    left: -120px;
    transition: left 0.2s ease-in-out;
    z-index: 1200;
}

.admin-page .menu-container:hover {
    left: 0;
}

.admin-page .menu-container .menu-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 18px;
    margin-top: 12px;
    justify-content: start;
    height: 100%;
}

.admin-page .menu-container a.menu-link {
    display: flex;
    text-decoration: none;
    color: var(--text-color);
    transition: color 0.2s ease-in-out;
    align-items: center;
    justify-content: end;
    gap: 4px;
}

.admin-page .menu-container:hover a.menu-link {
    justify-content: start;
}

.admin-page .menu-container a.logo-link {
    position: absolute;
    max-height: 70px;
    height: 100%;
    left: 0;
    right: -120px;
    margin: auto;
    width: fit-content;
    top: 30px;
    transition: right 0.2s ease-in-out;
}

.admin-page .menu-container:hover a.logo-link {
    right: 0;
}

.admin-page .menu-container .logo-link .menu-logo,
.admin-page .menu-container .logo-link .small-menu-logo {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.admin-page .menu-container .logo-link .menu-logo {
    width: 0;
    max-width: 180px;
    max-height: 180px;
}

.admin-page .menu-container .logo-link .small-menu-logo {
    max-width: 70px;
    max-height: 70px;
}

.admin-page .menu-container:hover .logo-link .menu-logo {
    width: 100%;
    transition: width 0.1s ease-in-out;
}

.admin-page .menu-container:hover .logo-link .small-menu-logo {
    display: none;
}

.admin-page .menu-container a.menu-link:hover,
.admin-page .menu-container a.menu-link:focus {
    color: var(--text-color);
}

.admin-page .menu-container .logout-link {
    margin-top: auto;
}

.admin-page .menu-container .menu-link-text {
    display: none;
    line-height: 14px;
    height: fit-content;
    position: relative;
    top: 1px;
}

.admin-page .menu-container:hover .menu-link-text {
    display: inline;
}
