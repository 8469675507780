.admin-page .user-container .user-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 450px;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.admin-page .user-container .row {
    flex-direction: column;
}

.admin-page .user-container .password-strength-container {
    width: fit-content;
    margin: auto;
    text-align: center;
    list-style: none;
    padding: 0;
}
