.frontend-page .courses-container {
    padding: 92px 24px 50px;
    max-width: 1320px;
    margin: auto;
    min-height: 100%;
}

.frontend-page .courses-container .course-filters-container {
    margin-bottom: 32px;
}

.frontend-page .courses-container .course-filters-title {
    font-size: 16px;
    line-height: 20px;
}

.frontend-page .courses-container .course-filters {
    margin-top: 16px;
}

.frontend-page .courses-container .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 12px;
}

.frontend-page .courses-container .MuiFormControlLabel-root .MuiCheckbox-root {
    display: none;
}

.frontend-page .courses-container .MuiFormControlLabel-root .MuiFormControlLabel-label {
    position: relative;
    display: flex;
    padding: 5px 15px;
    font-size: 14px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: 0.1px;
    line-height: 20px;
    color: var(--filter-text-color);
    border: 1px solid var(--filter-border-color);
    border-radius: 8px;
    transition: padding-left 250ms cubic-bezier(0.2, 0, 0, 1) 0ms, background-color 250ms cubic-bezier(0.2, 0, 0, 1) 0ms, box-shadow 250ms cubic-bezier(0.2, 0, 0, 1) 0ms;
}

.frontend-page .courses-container .MuiFormControlLabel-root .MuiFormControlLabel-label:hover {
    background: var(--filter-hover-color);
}

.frontend-page .courses-container .MuiFormControlLabel-root .Mui-checked + .MuiFormControlLabel-label {
    background: var(--filter-enabled-color);
    border-color: var(--filter-enabled-color);
    padding: 6px 16px 6px 34px;
    border: none;
}

.frontend-page .courses-container .MuiFormControlLabel-root .Mui-checked + .MuiFormControlLabel-label:hover {
    box-shadow: 0 1px 3px 1px #00000026, 0 1px 2px 0 #0000004D;
}

.frontend-page .courses-container .MuiFormControlLabel-root .MuiFormControlLabel-label:before {
    position: absolute;
    left: 8px;
    content: '';
    opacity: 0;
    display: block;
    width: 18px;
    height: 18px;
    background-image: url('../media/filter-selected.svg');
    transition: opacity 150ms cubic-bezier(0.2, 0, 0, 1) 0ms;
}

.frontend-page .courses-container .MuiFormControlLabel-root .Mui-checked + .MuiFormControlLabel-label:before {
    opacity: 1;
}

.frontend-page .courses-container .MuiFormControlLabel-root .Mui-checked + .MuiFormControlLabel-label:hover:after {
    position: absolute;
    display: block;
    content: '';
    inset: 0;
    background: var(--filter-enabled-hover-color);
}

@media (max-width: 1399px) {
    .frontend-page .courses-container {
        max-width: 1140px;
    }
}

@media (max-width: 1199px) {
    .frontend-page .courses-container {
        max-width: 960px;
    }
}

@media (max-width: 1024px) {
    .frontend-page .courses-container .MuiFormControlLabel-root .MuiFormControlLabel-label:hover {
        background: transparent;
    }

    .frontend-page .courses-container .MuiFormControlLabel-root .Mui-checked + .MuiFormControlLabel-label {
        background: var(--filter-enabled-color);
    }

    .frontend-page .courses-container .MuiFormControlLabel-root .Mui-checked + .MuiFormControlLabel-label:hover:after {
        display: none;
    }
}

@media (max-width: 991px) {
    .frontend-page .courses-container {
        max-width: 720px;
    }
}

@media (max-width: 767px) {
    .frontend-page .courses-container {
        max-width: 540px;
    }
}

@media (max-width: 575px) {
    .frontend-page .courses-container {
        max-width: unset;
    }
}
