.admin-page .user-group-container .user-group-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 450px;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.admin-page .user-group-container .row {
    flex-direction: column;
}
