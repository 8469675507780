.frontend-page .image-hero-container {
    position: relative;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
}

.frontend-page .image-hero-container .image-hero-banner {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.frontend-page .image-hero-container .image-hero-content {
    color: var(--text-color);
    background: rgb(0, 0, 0, 0.5);
    text-align: center;
    padding: 40px 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
}

.frontend-page .image-hero-container .image-hero-content button {
    width: fit-content;
    margin: 0 auto;
    border-radius: 4px;
    padding: 12px 24px;
    background: var(--button-color);
    border-color: var(--button-color);
    font-size: 16px;
}

.frontend-page .image-hero-container .image-hero-content button:hover {
    background: var(--button-hover-color);
    border-color: var(--button-hover-color);
}

.frontend-page .image-hero-container .image-hero-subtitle {
    font-size: 1.25rem;
    font-family: 'Exo Light', Roboto, Helvetica, Arial, sans-serif !important;
    margin-bottom: 1.5rem;
}
