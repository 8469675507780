.frontend-page .register-container {
    display: flex;
    min-height: 100%;
    padding-top: 52px;
    background: var(--text-color);
}

.frontend-page .register-container .register-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: auto;
    padding: 60px 24px;
    max-width: 406px;
    width: 100%;
    box-sizing: content-box;
}

.frontend-page .register-container .register-title {
    font-size: 36px;
    line-height: 42px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    margin-bottom: 36px;
    text-align: center;
}

.frontend-page .register-container .sso-register-link {
    width: 100%;
    display: block;
    height: 52px;
    line-height: 52px;
    text-decoration: none;
    background: var(--button-color);
    color: var(--text-color);
    letter-spacing: 0.46px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    border-radius: 4px;
    text-align: center;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.frontend-page .register-container .sso-register-link:hover {
    background: var(--button-hover-color);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.frontend-page .register-container .row {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    --bs-gutter-x: 0;
}

.frontend-page .register-container .register-form-wrapper .MuiButton-root {
    padding: 12px 24px;
    width: 100%;
    box-shadow: none;
    background: transparent;
    border: 1px solid #000933A6;
    color: var(--background-color);
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: 0.46px;
    max-height: 52px;
}

.frontend-page .register-container .password-strength-container {
    width: 100%;
    list-style: none;
    padding: 17px 12px;
    background: #F1F4F6;
    border: 1px solid #000D4D73;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.frontend-page .register-container .password-strength-container li:first-of-type {
    font-family: 'Exo SemiBold', Roboto, Helvetica, Arial, sans-serif !important;
}

.frontend-page .register-container .password-strength-container li:not(:first-of-type) {
    position: relative;
    padding-left: 24px;
}

.frontend-page .register-container .password-strength-container li.active:before {
    position: absolute;
    display: block;
    content: '';
    width: 16px;
    height: 14px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-image: url('../media/check-icon.svg');
    background-size: 16px 14px;
    background-repeat: no-repeat;
}

.frontend-page .register-container .login-helper-text {
    color: var(--background-color);
    cursor: default;
    text-align: center;
    font-size: 14px;
    margin-top: 0;
}

.frontend-page .register-container .login-link {
    display: block;
    color: var(--background-color);
    cursor: pointer;
    font-size: 16px;
}
