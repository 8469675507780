.frontend-page .courses-container .tag-container {
    border-bottom: 1px solid var(--badge-color);
    display: flex;
    height: 49px;
    line-height: 49px;
    margin-bottom: 44px;
}

.frontend-page .courses-container .tag {
    padding: 0 16px;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
}

.frontend-page .courses-container .tag.active,
.frontend-page .courses-container .tag.disabled {
    cursor: default;
}

.frontend-page .courses-container .tag.active {
    border-bottom: 2px solid var(--button-color);
    color: var(--button-color);
}

.frontend-page .courses-container .tag.disabled {
    color: var(--border-color);
}

.frontend-page .courses-container .course-list-container {
    position: relative;
}

.frontend-page .courses-container .course-list-wrapper {
    display: flex;
    flex-direction: column;
}

.frontend-page .courses-container .course-list-title {
    font-size: 24px;
    margin-bottom: 32px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--badge-color);
}

.frontend-page .courses-container .course-list-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 400px;
    gap: 48px;
}

.frontend-page .courses-container .MuiButton-root.load-more-button {
    display: flex;
    margin-top: 48px;
    background: transparent;
    border: 1px solid #000626E5;
    box-shadow: none;
    letter-spacing: 0.46px;
    color: var(--background-color);
    font-size: 16px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    padding: 8px 70px 8px 70px;
}

.frontend-page .courses-container .course-item-container,
.frontend-page .courses-container .course-item-container * {
    cursor: pointer;
}

.frontend-page .courses-container .course-item-container {
    transition: top 0.1s ease-in-out;
    text-decoration: none;
    color: var(--background-color);
    box-shadow: 0 4px 9px 0 #0000001A, 0 16px 16px 0 #00000017, 0 37px 22px 0 #0000000D, 0 66px 26px 0 #00000003, 0 103px 29px 0 #00000000;
    border-radius: 12px;
    overflow: hidden;
    font-size: 0;
    position: relative;
    top: 0;
}

.frontend-page .courses-container .course-item-container:hover {
    top: -4px;
}

.frontend-page .courses-container .course-item-thumbnail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.frontend-page .courses-container .course-item-thumbnail-skeleton {
    width: 100%;
    max-height: 200px;
    transform: none;
    border-radius: 0;
}

.frontend-page .courses-container .course-item-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow-clip-margin: unset;
}

.frontend-page .courses-container .course-item-thumbnail.loading {
    display: none;
}

.frontend-page .courses-container .course-item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 200px;
    font-size: initial;
    padding: 12px 20px 16px;
}

.frontend-page .courses-container .course-item-status {
    position: absolute;
    top: 16px;
    left: 16px;
    padding: 4px 8px 4px 8px;
    border-radius: 6px;
    font-size: 14px;
    color: var(--background-color);
    font-family: 'Exo SemiBold', Roboto, Helvetica, Arial, sans-serif !important;
}

.frontend-page .courses-container .course-item-status.inProgress {
    background: #ECB22A;
}

.frontend-page .courses-container .course-item-status.completed {
    background: #10D499;
}

.frontend-page .courses-container .course-item-status.failed {
    background: var(--error-color);
}

.frontend-page .courses-container .course-item-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-family: 'Exo SemiBold', Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 18px;
}

.frontend-page .courses-container .course-item-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-family: 'Exo Light', Roboto, Helvetica, Arial, sans-serif !important;
    margin-bottom: 12px;
}

.frontend-page .courses-container .course-item-details {
    display: flex;
    margin: auto 0 6px;
    font-family: 'Exo Light', Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 16px;
    line-height: 24px;
}

.frontend-page .courses-container .course-item-price {
    border-right: 1px solid #8B8B8B73;
    padding-right: 18px;
}

.frontend-page .courses-container .course-item-duration {
    padding-left: 18px;
}

.frontend-page .courses-container .course-item-footer {
    margin-top: 16px;
}

.frontend-page .courses-container .course-item-badges {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.frontend-page .courses-container .course-item-badges .item-badge {
    color: var(--background-color);
    font-family: 'Exo SemiBold', Roboto, Helvetica, Arial, sans-serif !important;
    padding: 4px 8px;
    font-size: 14px;
    background: var(--badge-color);
    border-radius: 6px;
}

.frontend-page .courses-container .no-courses-container {
    margin-top: 67px;
    padding: 12px 20px;
}

.frontend-page .courses-container .no-courses-title {
    font-size: 18px;
    text-align: center;
    font-family: 'Exo SemiBold', Roboto, Helvetica, Arial, sans-serif !important;
}

.frontend-page .courses-container .no-courses-subtitle {
    font-size: 16px;
    font-family: 'Exo Light', Roboto, Helvetica, Arial, sans-serif !important;
    text-align: center;
}

@media (max-width: 1399px) {
    .frontend-page .courses-container .course-list-content {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 1024px) {
    .frontend-page .courses-container .course-item-container:hover {
        top: 0;
    }
}

@media (max-width: 991px) {
    .frontend-page .courses-container .course-list-content {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 575px) {
    .frontend-page .courses-container .tag {
        width: 50%;
        text-align: center;
    }

    .frontend-page .courses-container .course-list-content {
        grid-auto-rows: 425px;
    }

    .frontend-page .courses-container .course-item-wrapper {
        height: 225px;
    }

    .frontend-page .courses-container .no-courses-container {
        margin-top: 55px;
    }
}
