.frontend-page .login-container {
    display: flex;
    min-height: 100%;
    padding-top: 52px;
    background: var(--text-color);
}

.frontend-page .login-container .login-form-wrapper {
    margin: auto;
    padding: 60px 24px;
    max-width: 406px;
    width: 100%;
    box-sizing: content-box;
}

.frontend-page .login-container .login-title {
    font-size: 36px;
    line-height: 42px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    margin-bottom: 48px;
    text-align: center;
}

.frontend-page .login-container .login-form-wrapper .MuiButton-root {
    padding: 12px 24px;
    width: 100%;
    box-shadow: none;
    background: transparent;
    border: 1px solid #000933A6;
    color: var(--background-color);
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: 0.46px;
    max-height: 52px;
}

.frontend-page .login-container .sso-login-link {
    width: 100%;
    display: block;
    height: 52px;
    line-height: 52px;
    text-decoration: none;
    background: var(--button-color);
    color: var(--text-color);
    letter-spacing: 0.46px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    border-radius: 4px;
    text-align: center;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.frontend-page .login-container .sso-login-link:hover {
    background: var(--button-hover-color);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.frontend-page .login-container .divider-container {
    position: relative;
    height: 30px;
    margin: 24px 0;
}

.frontend-page .login-container .divider {
    border-bottom: 1px solid var(--badge-color);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
}

.frontend-page .login-container .divider-text {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    line-height: 30px;
    background: var(--text-color);
    font-size: 20px;
    padding: 0 16px;
}

.frontend-page .login-container .register-helper-text {
    color: var(--background-color);
    cursor: default;
    text-align: center;
    font-size: 14px;
    margin-top: 0;
}

.frontend-page .login-container .register-link {
    display: block;
    color: var(--background-color);
    cursor: pointer;
    font-size: 16px;
}
