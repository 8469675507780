.frontend-page .profile-container {
    display: flex;
    min-height: 100%;
    padding-top: 52px;
    background: var(--text-color);
}

.frontend-page .profile-container .profile-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: auto;
    padding: 60px 24px;
    max-width: 406px;
    width: 100%;
    box-sizing: content-box;
}

.frontend-page .profile-container .profile-title {
    font-size: 36px;
    line-height: 42px;
    font-family: 'Exo Medium', Roboto, Helvetica, Arial, sans-serif !important;
    margin-bottom: 36px;
    text-align: center;
}

.frontend-page .profile-container .row {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    --bs-gutter-x: 0;
}

.frontend-page .profile-container .password-strength-container {
    width: fit-content;
    margin: auto;
    text-align: center;
    list-style: none;
    padding: 0;
}
