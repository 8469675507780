.frontend-page .homepage-container {
    padding: 52px 0 0;
    min-height: 100%;
    height: 100%;
    font-size: 0;
}

.frontend-page .homepage-container .homepage-skeleton {
    width: 100%;
    max-width: 1272px;
    height: calc(100vh - 146px);
    margin: 40px auto 50px;
}

.frontend-page .homepage-container .MuiCircularProgress-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.frontend-page .homepage-container > div {
    font-size: initial;
}

.frontend-page .homepage-container .content-block.bannerBlock {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.frontend-page .homepage-container .textBlock {
    padding: 0 40px;
}

@media (max-width: 1399px) {
    .frontend-page .homepage-container .homepage-skeleton {
        max-width: 1092px;
    }
}

@media (max-width: 1199px) {
    .frontend-page .homepage-container .homepage-skeleton {
        max-width: 912px;
    }
}

@media (max-width: 991px) {
    .frontend-page .homepage-container .homepage-skeleton {
        max-width: 672px;
    }
}

@media (max-width: 767px) {
    .frontend-page .homepage-container .homepage-skeleton {
        max-width: 492px;
    }
}

@media (max-width: 575px) {
    .frontend-page .homepage-container .homepage-skeleton {
        max-width: calc(100vw - 48px);
    }
}
